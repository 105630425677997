
export default {
    name: 'ProductLike',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: Object,
        middleEmit: Object,
        recData: Object
    },
    data() {
        return {
            visible: false,
            hasLoading: false,
            hasSticky: false,
            listCompo: { // 列表组件配置
                list: [],
                options: {
                    hasMore: true,
                },
                page: {
                    hasMore: true,
                    totalPage: 0,
                    pageSize: 60,
                },
            },
            tabCompo: { // tab组件配置
                list: [],
                key: {},
            },
            category: {
                visible: false,
                list: []
            },
            page: {
                pageSize: 60,
                pageNum: 1,
                totalPage: 1,
            },
            params: {
                id: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getSeoRec(this.recData)
    },
    mounted() {
        // this.getCategoryAjax()
        // this.getList()
    },
    methods: {
        // 获取分类
        getCategoryAjax() {
            this.$api.product.getCategory({
                spuCode: this.query.code,
            }).then(response => {
                const res = response.result || [];
                this.category.list = res
                this.category.visible = !!this.category.list.length
                this.setCategoryData()
            })
        },
        // 获取分类列表
        getCategoryListAjax() {
            this.$api.product.getCategoryList({
                catId: this.params.id,
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
            }, this.hasLoading).then(response => {
                const res = response.result || {};
                this.page.pageNum == 1 && (this.listCompo.list = [])
                this.listCompo.list = [...this.listCompo.list, ...(res.list || [])]
                this.visible = !!this.listCompo.list.length
                this.page.totalPage = res.totalPage || 10
                this.listCompo.page.totalPage = this.page.totalPage
            })
        },
        // 推荐
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 2,
                spuCode: this.query.code,
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
            }, this.hasLoading).then(response => {
                const res = response.result || {};
                this.page.pageNum == 1 && (this.listCompo.list = [])
                this.listCompo.list = [...this.listCompo.list, ...(res.list || [])]
                this.visible = !!this.listCompo.list.length
                this.page.totalPage = res.totalPage || 10
                this.listCompo.page.totalPage = this.page.totalPage
            })
        },
        getSeoRec(res){
            this.page.pageNum == 1 && (this.listCompo.list = [])
            this.listCompo.list = [...this.listCompo.list, ...(res.list || [])]
            this.visible = !!this.listCompo.list.length
            this.page.totalPage = res.totalPage || 10
            this.listCompo.page.totalPage = this.page.totalPage
        },
        // 获取列表数据
        getList() {
            if(this.params.id) {
                this.getCategoryListAjax()
            } else {
                this.getRecAjax()
            }
        },
        // 设置分类数据
        setCategoryData() {
            if(!this.category.visible) return
            this.tabCompo.list = [{
                catId: '',
                catName: this.$translate('Recommended'),
                level: 0
            }, ...this.category.list];
            this.tabCompo.key = {
                title: 'catName',
                name: 'catId'
            }
        },
        // 分类
        changeTab(e) {
            this.page.pageNum = 1
            this.getList()
            this.setScrollTo()
        },
        changeSticky(e) {
            this.hasSticky = e
        },
        setScrollTo() {
            if(this.hasSticky) {
                this.$VueScrollTo.scrollTo('.ProductRec', 0, {
                    offset: 0
                })
            }
        },
        // 触底加载
        emitBottomLoading(e, callBack) {
            const hasNext = this.page.totalPage > this.page.pageNum;
            if(e.isIntersecting && hasNext) {
                this.page.pageNum += 1
                // this.hasLoading = true
                // this.getRecAjax()
                this.getList()
            }
            callBack(!hasNext)
        },
        recObserve(e) {
            const first = e[0] || {};
            this.middleEmit.fn('emitRecObserve', {isIntersecting: first.isIntersecting})
        }
    },
}
