
import ProductPolicyPopup from './ProductPolicyPopup'

export default {
    name: 'ProductPolicy',
    components: {
        ProductPolicyPopup
    },
    props: {
        info: Object,
        skuSelect: Object,
    },
    data() {
        return {
            country: {},
            list: [],
            popup: {
                visible: false,
                title: '',
                list: []
            },
            isHost:window.location.host.includes('www.yfn.com')
        }
    },
    computed: {},
    watch: {
        skuSelect: {
            handler() {
                this.setShippingData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {
        this.country = this.$storage.get('country') || {}
        this.getShippingAjax()
    },
    methods: {
        // 获取运输协议
        getShippingAjax() {
            this.$api.common.getShipping({
                countryCode: this.country.code
            }).then(response => {
                let res = response.result || [];
                res.forEach(item => {
                    // 运输协议
                    let priceText = !item.fee ? this.$translate('Free') : `${this.$price(item.displayFee)}`
                    let maxPriceText = `${this.$price(item.displayFreeLimit)}`
                    let deliverTime = `${item.minDayStr}~${item.maxDayStr}`
                    let deliveredText = this.skuSelect.standby ? '' : `${this.$translate('Estimated to be delivered on')} ${deliverTime}`;
                    const freeText = item.fee ? `${this.$translate(item.name)}: ${priceText}, ${this.$translate('Free')} ${this.$translate('shipping over')} ${maxPriceText}` : `${this.$translate('Free Worldwide Standard Shipping')}<br>`;
                    this.list.push({
                        ...item,
                        text: `${freeText} ${deliveredText}`,
                        freeText,
                        deliveredText,
                        visible: true
                        // text: `${freeText}`
                    })
                    // 运输协议弹窗
                    this.popup.list.push({
                        title: this.$translate(item.name),
                        time: `${item.minDay}-${item.maxDay} ${this.$translate('workdays')}`,
                        displayFee: `${this.$price(item.displayFee)}`,
                        text: !item.fee ? this.$translate('Free') : `${this.$translate('Free on orders over')} ${maxPriceText}`
                    })
                })
                this.setShippingData()
            })
        },
        setShippingData() {
            // 延迟发货处理
            this.list.forEach(p => {
                if(this.skuSelect.standby) {
                    if(p.name == 'Standard Shipping') {
                        p.text = p.freeText
                    }
                    if(p.name == 'Express Shipping') {
                        // p.visible = false
                    }
                } else {
                    p.visible = true
                    p.text = `${p.freeText} ${p.deliveredText}`
                }
            })
        },
        handlePolicy(obj) {
            let title = '';
            if(obj.type == 1) {
                title = this.isHost ? this.$translate('Free Worldwide Shipping') : this.$translate('Shipping Policy')
            } else if(obj.type == 3) {
                title = 'Shopping Security'
            } else {
                this.$router.push({
                    name: 'Policy',
                    query: {
                        id: '1679369573013aP5MjpvZ'
                    }
                })
                return
            }
            this.popup.visible = true
            this.popup.type = obj.type
            this.popup.title = this.$translate(title)
        }
    },
}
