
export default {
    name: 'ProductSeo',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        rec: Object
    },
    data() {
        return {
            currency: {},
            isClient:false,
        }
    },
    computed: {},
    watch: {},
    created() {
        this.currency = this.$storage.get('currency') || {}
    },
    mounted() {
        this.isClient = process.client
    },
    methods: {
        backCat(info){
            let batCate = info?.backCat?.pullPathEn.split('/') || []
            return batCate?.pop()
        }
    },
}
